import _ from 'lodash'

const common = [
    ['s', 'e', 'o'],
    ['a', 'u', 'd', 'i', 't'],
    ['m', 'a', 'n', 'a', 'g', 'e', 'r'],
    ['o','p','t','i','m','i','z','a','t','i','o','n']
]

const names = [
    ['d', 'a', 'n', 'i', 'e', 'l'],
    ['m', 'o', 'o', 'r', 'e'],
    ['b','r','o','w','n']
]

const sentences = [
    ["r", "e", "p", "r", "e", "s", "e", "n", "t", "a", "c", "o", "m", "p", "a", "n", "y", "t", "h", "a", "t", "e", "m", "p", "l", "o", "y", "s"],
    ["t", "h", "e", "a", "u", "d", "i", "t", "o", "f", "y", "o", "u", "r", "s", "i", "t", "e", "i", "s", "c", "o", "m", "p", "l", "e", "t", "e", "l", "y", "f", "r", "e", "e"],
    ["t", "h", "e", "r", "e", "a", "r", "e", "s", "e", "v", "e", "r", "a", "l", "e", "r", "r", "o", "r", "s", "i", "n", "y", "o", "u", "r", "w", "e", "b", "s", "i", "t", "e", "c", "o", "d", "e"],
    ["t", "h", "e", "r", "e", "a", "r", "e", "s", "e", "v", "e", "r", "a", "l", "e", "r", "r", "o", "r", "s", "i", "n", "y", "o", "u", "r", "s", "i", "t", "e", "c", "o", "d", "e"]
]

const susByKnownSpam = (fields: WixCodeField[]) => {
    try {
        const flatCommon = _.map(common, c => c.join(''))
        const flatNames = _.map(names, n => n.join(''))
        const flatSentences = _.map(sentences, s => s.join(''))

        return !!_.find(fields, field => {
            const value = _.toLower(field.value)

            const inCommon = _.filter(flatCommon, c => _.includes(value, c))
            const inFlatNames = _.filter(flatNames, n => _.includes(value, n))

            let inSentences = []
            
            try {
                inSentences = _.filter(flatSentences, s => _.includes(_.replace(value, / /ig, ''), s))
            } catch {}

            return inCommon.length > 1 || inFlatNames.length > 1 || inSentences.length > 0
        })
    } catch (err) {
        return false
    }
}

export const isSus = (fields: WixCodeField[]) => susByKnownSpam(fields)